import React from 'react'
import "./newnavbar.css"
import { NavLink } from "react-router-dom";
function Newnavbar() {
  return (
    <div className="new_nav">
        <div className="nav_data">
            <div className="left_data">
            <p>ALL</p>
            <p>Architect</p>
            <p>Contractor</p>
            <p>Sell</p>
            < NavLink to={`/products/House`} ><p>House</p></NavLink>
          
            <p>Fruniture</p>
            <p>Design</p>
            <p>Decoration</p>
            <p>Material</p>
            <p>Customer Service</p>
            </div>
            <div className="right_data">
                
            </div>
        </div>
    </div>
  )
}

export default Newnavbar