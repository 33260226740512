import React from 'react'
import "./Card.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { features } from './features';
import { Divider } from '@mui/material';
import { NavLink } from "react-router-dom";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

const Card = () => {


    return (
        <div className="Card_section">
            <div className="Card_deal">
                <h3>Service we Provide</h3>
                
            </div>
            <Divider />

            <Carousel
                responsive={responsive}
                infinite={true}
                draggable={false}
                swipeable={true}
                centerMode={true}
                autoPlay={true}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                showDots={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item"
                containerClass="carousel-container"
            >
                {
                    features.map((e) => {
                        return (
                            < NavLink to={`/products/${e.title}`} >
                                <div className="Card_items">
                                    <div className="Card">
                                    <div className="Card_img">
                                        <img src={e.url} alt="product" />
                                    </div>
                                    <p className="Card_name">{e.title}</p>
                                    
                                    <button className="view_btn">View All</button>
                                    </div>
                                </div>
                                </NavLink>
                        )
                    })
                }

            </Carousel>
        </div>
    )
}

export default Card