import React from 'react'
import "../footer/footer.css";


const Footer = () => {

    const year = new Date().getFullYear();
    console.log(year);

    return (
        <footer>
            <div className="footer_container">
                <div className="footr_details_one">
                    <h3>Get to Know US</h3>
                    <p>About us</p>
                    <p>Careers</p>
                    <p>Press Releases</p>
                    <p>Comapny Cares</p>
                </div>
                <div className="footr_details_one">
                    <h3>Connect with Us</h3>
                    <p>Facebook</p>
                    <p>Twitter</p>
                    <p>Instagram</p>
                </div>
                <div className="footr_details_one forres">
                    <h3>Make Money with Us</h3>
                    <p>Sell on Comapny</p>
<p>Sell under Company Accelerator</p>
<p>Protect and Build Your Brand</p>
<p>Comapny Global Selling</p>
<p>Become an Affiliate</p>
<p>Fulfilment by Company</p>
<p>Advertise Your Products</p>
<p>Company Pay on Merchant</p>
                   
                </div>
                <div className="footr_details_one forres">
                    <h3>Address</h3>
                    <p>Durga Nagar</p>
                    <p>Panchavati,Nashik</p>
                    <p>Email:Comapny@gmail.com</p>
                </div>
            </div>
            <div className="lastdetails">
                <img src="./amazon_PNG25.png" alt="logo" />
                <p>Conditions of Use & Sale &nbsp; &nbsp;&nbsp;  Privacy Notice  &nbsp; &nbsp;&nbsp; Interest-Based Ads  &nbsp; &nbsp;&nbsp;  © 1996-{year}, Company.com, Inc. or its affiliates</p>
            </div>
        </footer>
    )
}

export default Footer