export const god = [
    { 
        id: 'product69',
        url: 'https://blogs.revv.co.in/blogs/wp-content/uploads/2020/11/Somnath-Temple.jpg', 
        detailUrl: 'https://blogs.revv.co.in/blogs/wp-content/uploads/2020/11/Somnath-Temple.jpg',
        title: "Temple", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product70',
        url: 'https://deih43ym53wif.cloudfront.net/blue-mosque-glorius-sunset-istanbul-sultan-ahmed-turkey-shutterstock_174067919.jpg_1404e76369.jpg', 
        detailUrl: 'https://deih43ym53wif.cloudfront.net/blue-mosque-glorius-sunset-istanbul-sultan-ahmed-turkey-shutterstock_174067919.jpg_1404e76369.jpg',
        title: "Mosque",
        description: 'design and construction',
         
    },
    { 
        id: 'product71',
        url: 'https://www.dsource.in/sites/default/files/resource/pandavleni-nashik/trirashmi-buddha-vihar/minigallery/23365/22_b_place_new.jpgg',
        detailUrl: 'https://www.dsource.in/sites/default/files/resource/pandavleni-nashik/trirashmi-buddha-vihar/minigallery/23365/22_b_place_new.jpgg', 
        title:"BuddhaVihar", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product72',
        url: 'https://images.herzindagi.info/image/2021/Jul/gurudwara-india-main.jpg', 
        detailUrl: 'https://images.herzindagi.info/image/2021/Jul/gurudwara-india-main.jpg8',
        title: "Gurudwara", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    
   

]