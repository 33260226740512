import React from 'react'
import {indu} from '../data/indu'
import './house.css'
import ReactWhatsapp from 'react-whatsapp';
const Industry=()=> {
const itemCard=indu.map((e)=>
  <div className="Card_items">
  <div className="Card">
  <div className="Card_img">
      <img src={e.url} alt="product" />
  </div>
  <p className="Card_name">{e.title}</p>
  
  <ReactWhatsapp number="+917709944260" message={ e.title} className="view_btn">BUY</ReactWhatsapp>
  </div>
</div>
)


  return (
    <div className='dispalytv'>
      {itemCard}
    </div>
    
  )
  }

export default Industry