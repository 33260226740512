
import React from 'react'
import Banner from './Banner'
import Slide from './Slide'
import Card from './Card'
import Design from './Design'

const Maincomp=()=> {
  return (
    <div className='home_section'>
        <div className="banner_part">
            <Banner/>
        </div>
        <Slide/>
        <Card/>
        <Design/>
        
    </div>
  )
}

export default Maincomp

