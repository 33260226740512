import Nvabar from './component/header/Nvabar';
import Newnavbar from './component/newnavbar/Newnavbar';
import Maincomp from './component/homecomp/Maincomp';
import Footer from './component/footer/Footer';
import SignUp from './component/signup_signin/SignUp';
import Signin from './component/signup_signin/Signin';
import Cart from './component/cart/Cart';
import Buynow from './component/buynow/Buynow';
import House from './component/design/House';
import Industry from './component/design/Industry';
import Bathroom from './component/design/Bathroom';
import Bedroom from './component/design/Bedroom';
import Cafe from './component/design/Cafe';
import Ceiling from './component/design/Ceiling';
import College from './component/design/College';
import Godhome from './component/design/Godhome';
import Hotel from './component/design/Hotel';
import Interior from './component/design/Interior';
import Kitchen from './component/design/Kitchen';
import Living from './component/design/Living';
import Office from './component/design/Office';
import Outdoor from './component/design/Outdoor';
import Tiles from './component/design/Tiles';
import Wall from './component/design/Wall';
import './App.css';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Routes, Route } from "react-router-dom";


function App() {
  const [data, setData] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setData(true);
    }, 2000);
  }, [])


  return (
    <>
      {
        data ? (
          <>
            <Nvabar />
            <Newnavbar />
            <Routes>
              <Route exact path="/" element={<Maincomp />}>
                
              </Route>
              <Route exact path="/signup" element={<SignUp />}>
                
              </Route>
              <Route exact path="/login" element={<Signin/>}>
              </Route>
              <Route exact path="/products/House" element={<House />}>
              </Route>
              <Route exact path="/products/Industry" element={<Industry/>}>
                
              </Route>
              <Route exact path="/products/Bathroom" element={<Bathroom/>}>
              </Route>
              <Route exact path="/products/Bedroom" element={<Bedroom/>}>
              </Route>
              <Route exact path="/products/Cafe" element={<Cafe/>}>
              </Route>
              <Route exact path="/products/Ceiling" element={<Ceiling/>}>
              </Route>
              <Route exact path="/products/College" element={<College/>}>
              </Route>
              <Route exact path="/products/Godhome" element={<Godhome/>}>
              </Route>
              <Route exact path="/products/Hotel" element={<Hotel/>}>
              </Route>
              <Route exact path="/products/Kitchen" element={<Kitchen/>}>
              </Route>
              <Route exact path="/products/Living" element={<Living/>}>
              </Route>
              <Route exact path="/products/Office" element={<Office/>}>
              </Route>
              <Route exact path="/products/Outdoor" element={<Outdoor/>}>
              </Route>
              <Route exact path="/products/Tiles" element={<Tiles/>}>
              </Route>
              <Route exact path="/products/Wall" element={<Wall/>}>
              </Route>
              <Route exact path="/getproductsone/:id" element={<Cart />}>
                
              </Route>
              <Route exact path="/buynow" element={<Buynow />}>
              
              </Route>
            </Routes>
            <Footer />
          </>
        ) : (
          <div className="circle">
            <CircularProgress />
            <h2> Loading....</h2>
          </div>
        )
      }

    </>
  );
}

export default App;
