export const hous = [
    { 
        id: 'product21',
        url: 'https://www.homestratosphere.com/wp-content/uploads/2018/02/mansion-contemporary-hs-870x579.jpg.webp', 
        detailUrl: 'https://www.homestratosphere.com/wp-content/uploads/2018/02/mansion-contemporary-hs-870x579.jpg.webp',
        title: "Villa", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product22',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Cottage.jpg', 
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Cottage.jpg',
        title: "Cottage",
        description: 'design and construction',
         
    },
    { 
        id: 'product23',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Single-Family-Home.jpg', 
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Single-Family-Home.jpg', 
        title:"Single Family Home", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product24',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Apartment.jpg', 
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Apartment.jpg',
        title: "Apartment", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    { 
        id: 'product25',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Multi-family.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Multi-family.jpg', 
        title: "Multi-family",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product26',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Penthouse.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Penthouse.jpg', 
        title: "Penthouse",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product27',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Ranch-House.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Ranch-House.jpg', 
        title: "Ranch House",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product28',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/House-Boat.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/House-Boat.jpg', 
        title: " House Boat",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product29',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Bungalow.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Bungalow.jpg', 
        title: " Bungalow",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product30',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Chalet.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Chalet.jpg', 
        title: "Chalet",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product31',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Round-House.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Round-House.jpg', 
        title: "Round House",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product32',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Mobile-Home.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Mobile-Home.jpg', 
        title: "Mobile Home",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product33',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Mansion.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Mansion.jpg', 
        title: "Mansion",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product34',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Converted-Shipping-Container.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Converted-Shipping-Container.jpg', 
        title: "Shipping Container",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product35',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Farm-House.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Farm-House.jpg', 
        title: " Farm House",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product36',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Basement-Suite.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Basement-Suite.jpg', 
        title: "Basement Suite",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product37',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/TreeHouse.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/TreeHouse.jpg', 
        title: " TreeHouse",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product38',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Tiny-Home.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Tiny-Home.jpg', 
        title: "Tiny Home",
      
        description: 'Every type of office we made',
         
    }
    ,{ 
        id: 'product39',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Underground-House.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Underground-House.jpg', 
        title: "Underground House",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product40',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Converted-Shipping-Container.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Converted-Shipping-Container.jpg', 
        title: "Studio Home",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product41',
        url: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Converted-Shipping-Container.jpg',
        detailUrl: 'https://www.homenish.com/wp-content/uploads/2020/types-of-houses/Converted-Shipping-Container.jpg', 
        title: "Shipping Container",
      
        description: 'Every type of office we made',
         
    }

]