export const hot = [
    { 
        id: 'product21',
        url: 'https://www.cvent.com/sites/default/files/styles/column_content_width/public/image/2021-09/two%20people%20relaxing%20by%20pool%20at%20luxury%20resort.jpg?itok=3UT6TTT4', 
        detailUrl: 'https://www.cvent.com/sites/default/files/styles/column_content_width/public/image/2021-09/two%20people%20relaxing%20by%20pool%20at%20luxury%20resort.jpg?itok=3UT6TTT4',
        title: "Resorts", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product22',
        url: 'https://en.allsuites-apparthotel.com/var/pichet_anciens/storage/images/media/images/all-suites-appart-hotel-bdx-pessac-studio-confort3/1132501-1-eng-GB/all-suites-appart-hotel-bdx-pessac-studio-confort.jpg', 
        detailUrl: 'https://en.allsuites-apparthotel.com/var/pichet_anciens/storage/images/media/images/all-suites-appart-hotel-bdx-pessac-studio-confort3/1132501-1-eng-GB/all-suites-appart-hotel-bdx-pessac-studio-confort.jpg',
        title: "AllSuites",
        description: 'design and construction',
         
    },
    { 
        id: 'product23',
        url: 'https://images.cvent.com/CSN/04775071-b259-4ef3-8af2-a4b70e37169b/images/7ed6af52ee694953b96c6bce1fef9f6a_LARGE!_!4d4302d8b73920b15cb6716c806b39c6.jpg', 
        detailUrl: 'https://images.cvent.com/CSN/04775071-b259-4ef3-8af2-a4b70e37169b/images/7ed6af52ee694953b96c6bce1fef9f6a_LARGE!_!4d4302d8b73920b15cb6716c806b39c6.jpg', 
        title:"convention cente", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product24',
        url: 'https://images.adsttc.com/media/images/5b0e/461f/f197/ccc1/fc00/005c/newsletter/Silver_Linings_-_Architecture1.jpg?1527662100', 
        detailUrl: 'https://images.adsttc.com/media/images/5b0e/461f/f197/ccc1/fc00/005c/newsletter/Silver_Linings_-_Architecture1.jpg?1527662100',
        title: "Boutique", 
       
        description: 'Temple,Church,Mosque and Gurudwara',
        
    },
    { 
        id: 'product25',
        url: 'https://conestogalogcabins.com/wp-content/uploads/2022/10/Camp-Emerson-006.jpg',
        detailUrl: 'https://conestogalogcabins.com/wp-content/uploads/2022/10/Camp-Emerson-006.jpg', 
        title: "Bunkhouses",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product26',
        url: 'https://luxurylondon.co.uk/wp-content/uploads/2022/08/eco-hotels-nihi-sumba.jpg',
        detailUrl: 'https://luxurylondon.co.uk/wp-content/uploads/2022/08/eco-hotels-nihi-sumba.jpg', 
        title: "Eco",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product27',
        url: 'https://media-cdn.tripadvisor.com/media/photo-s/1c/b8/ac/77/ameristar-casino-hotel.jpg',
        detailUrl: 'https://media-cdn.tripadvisor.com/media/photo-s/1c/b8/ac/77/ameristar-casino-hotel.jpg', 
        title: "Casino hotel",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product28',
        url: 'https://www.mews.com/hs-fs/hubfs/pop-up-Thumbnail_600x395.jpg?width=600&height=395&name=pop-up-Thumbnail_600x395.jpg',
        detailUrl: 'https://www.mews.com/hs-fs/hubfs/pop-up-Thumbnail_600x395.jpg?width=600&height=395&name=pop-up-Thumbnail_600x395.jpg', 
        title: "Pop-up",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product29',
        url: 'https://www.soegjobs.com/wp-content/uploads/2017/04/Prahran_Melbourne_Innovative_Hotel_Concept.jpg',
        detailUrl: 'https://www.soegjobs.com/wp-content/uploads/2017/04/Prahran_Melbourne_Innovative_Hotel_Concept.jpg', 
        title: " Unique concep",
      
        description: 'Every type of office we made',
         
    },
    

]