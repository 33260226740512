export const caf = [
    { 
        id: 'product51',
        url: 'https://imgmedia.lbb.in/media/2019/12/5de4d3e5d6c5ac59bbeb0fe5_1575277541956.jpg?w=1200&h=628&fill=blur&fit=fill', 
        detailUrl: 'https://imgmedia.lbb.in/media/2019/12/5de4d3e5d6c5ac59bbeb0fe5_1575277541956.jpg?w=1200&h=628&fill=blur&fit=fill',
        title: "Fine Dining", 
        
        description: 'We provide 30 type of house',
         
    },
    { 
        id: 'product52',
        url: 'https://b.zmtcdn.com/data/pictures/0/18292670/5141bd12978b1b1acc2c5398041a58f6_featured_v2.jpg', 
        detailUrl: 'https://b.zmtcdn.com/data/pictures/0/18292670/5141bd12978b1b1acc2c5398041a58f6_featured_v2.jpg',
        title: "Casual Dining",
        description: 'design and construction',
         
    },
    { 
        id: 'product53',
        url: 'https://media-cdn.tripadvisor.com/media/photo-s/14/5c/cb/22/fast-casual-tasty.jpg',
        detailUrl: 'https://media-cdn.tripadvisor.com/media/photo-s/14/5c/cb/22/fast-casual-tasty.jpg', 
        title:"Fast Casual", 
        
        description: 'Best Place to work',
        
    },
    { 
        id: 'product54',
        url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Fast_Food_Restaurant_in_Malinska_im_Hafen.jpg/1200px-Fast_Food_Restaurant_in_Malinska_im_Hafen.jpg',
        detailUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Fast_Food_Restaurant_in_Malinska_im_Hafen.jpg/1200px-Fast_Food_Restaurant_in_Malinska_im_Hafen.jpg', 
        title: "Fast Food",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product55',
        url: 'https://media.licdn.com/dms/image/C5612AQHPedj-ibTwgA/article-cover_image-shrink_720_1280/0/1631782156296?e=2147483647&v=beta&t=7IKyckUhq0xqh9V-Zd_SNQCoGJx1STk1qsSI7mXywH',
        detailUrl: 'https://media.licdn.com/dms/image/C5612AQHPedj-ibTwgA/article-cover_image-shrink_720_1280/0/1631782156296?e=2147483647&v=beta&t=7IKyckUhq0xqh9V-Zd_SNQCoGJx1STk1qsSI7mXywH', 
        title: "Food Truck",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product56',
        url: 'https://housing.com/news/wp-content/uploads/2022/11/cafe-design-compressed-1.jpg',
        detailUrl: 'https://housing.com/news/wp-content/uploads/2022/11/cafe-design-compressed-1.jpg', 
        title: " Cafe",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product57',
        url: 'https://blog.venuelook.com/wp-content/uploads/2020/02/Bar-660x330.jpg',
        detailUrl: 'hhttps://blog.venuelook.com/wp-content/uploads/2020/02/Bar-660x330.jpg', 
        title: "Pub",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product58',
        url: 'https://5.imimg.com/data5/SELLER/Default/2021/12/QC/XW/RH/138773694/cafe-interior-designers-india-1000x1000.jpg',
        detailUrl: 'https://5.imimg.com/data5/SELLER/Default/2021/12/QC/XW/RH/138773694/cafe-interior-designers-india-1000x1000.jpg', 
        title: "Coffee House",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product59',
        url: 'https://media-cdn.tripadvisor.com/media/photo-s/05/c5/62/60/teppanyaki.jpg',
        detailUrl: 'https://media-cdn.tripadvisor.com/media/photo-s/05/c5/62/60/teppanyaki.jpg', 
        title: "Teppanyaki Grill",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product60',
        url: 'https://media-cdn.tripadvisor.com/media/photo-s/04/0b/bb/08/barbeque-nation-bangalore.jpg',
        detailUrl: 'https://media-cdn.tripadvisor.com/media/photo-s/04/0b/bb/08/barbeque-nation-bangalore.jpg', 
        title: "Mongolian Barbecue",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product61',
        url: 'https://im1.dineout.co.in/images/uploads/restaurant/sharpen/9/m/b/p97387-164009074861c1cc7c7be6c.jpg?tr=tr:n-xlarge',
        detailUrl: 'https://im1.dineout.co.in/images/uploads/restaurant/sharpen/9/m/b/p97387-164009074861c1cc7c7be6c.jpg?tr=tr:n-xlarge', 
        title: "Bistro",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product62',
        url: 'https://golden-storage-production.s3.amazonaws.com/topic_images/62d95ec07150486eb55bfaa15cf7e30b.jpeg',
        detailUrl: 'https://golden-storage-production.s3.amazonaws.com/topic_images/62d95ec07150486eb55bfaa15cf7e30b.jpeg', 
        title: "Contemporary Casual",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product63',
        url: 'https://media.architecturaldigest.com/photos/55e791cc302ba71f3017ea52/master/w_900,h_600,c_limit/dam-images-daily-2015-06-most-beautiful-restaurants-most-beautiful-restaurants-09.jpg',
        detailUrl: 'https://media.architecturaldigest.com/photos/55e791cc302ba71f3017ea52/master/w_900,h_600,c_limit/dam-images-daily-2015-06-most-beautiful-restaurants-most-beautiful-restaurants-09.jpg', 
        title: "Diner",
      
        description: 'Every type of office we made',
         
    },
    { 
        id: 'product64',
        url: 'https://sirvo.com/wp-content/uploads/sites/16/2016/12/pop-up-container-kitchen-or-restaurant-4.jpg',
        detailUrl: 'https://sirvo.com/wp-content/uploads/sites/16/2016/12/pop-up-container-kitchen-or-restaurant-4.jpg', 
        title: " Pop-Up",
      
        description: 'Every type of office we made',
         
    }
   

]